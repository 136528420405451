<template>
  <div id="app" class="app-body">
    <div class="spacer-small"></div>
    <div class="columns">
      <div class="column is-6">
        <p class="is-subtitle is-secondary is-bold">Purchase Details</p>

        <div class="mt-5">
          <p class="is-secondary is-bold">Service</p>
          <div>
            <p>Logo Design</p>
          </div>

          <p class="is-secondary is-bold mt-5">Package</p>
          <div>
            <p>Standard</p>
          </div>

          <p class="is-secondary is-bold mt-5">Price</p>
          <div>
            <p>40,000 PKR</p>
          </div>

          <div class="spacer-small"></div>

          <p class="is-secondary is-bold">Account Number</p>
          <div class="my-5">
            <b-input></b-input>
          </div>

          <div class="columns">
            <div class="column">
              <p class="is-secondary is-bold">Security Code</p>
              <div class="my-5">
                <b-input></b-input>
              </div>
            </div>
            <div class="column">
              <p class="is-secondary is-bold">Expiry</p>
              <div class="my-5">
                <b-input></b-input>
              </div>
            </div>
          </div>

          <div class="my-5 pt-5">
            <router-link :to="{ name: 'Dashboard' }">
              <b-button type="is-info" icon-left="check">
                Make Payment
              </b-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="column has-text-centered">
        <img
          src="@/assets/img/home/create_brief_illustration.svg"
          width="90%"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkout",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
